import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/Layout"
import MetaData from "../../components/meta"
import RelatedResourceCard from "../../components/RelatedResourceCard"

// Import styles
import "../../styles/docker-swarm-ingress.scss"

// Images
import tech__docker from "../../images/docker-swarm/technologies/docker.svg"
import tech__kubernetes from "../../images/docker-swarm/technologies/kubernetes.svg"
import tech__aws from "../../images/docker-swarm/technologies/aws.svg"
import tech__gc from "../../images/docker-swarm/technologies/google-cloud.svg"
import tech__microsoft from "../../images/docker-swarm/technologies/microsoft-azure.svg"
import diagram from "../../images/docker-swarm/diagram-docker-swarm.svg"
import benefits__connect from "../../images/docker-swarm/benefits/connect-illustration.svg"
import benefit__monitor from "../../images/docker-swarm/benefits/monitor-illustration.svg"
import benefit__secure from "../../images/docker-swarm/benefits/secure-illustration.svg"
import customers__expedia from "../../images/docker-swarm/customers/expedia.svg"
import customers__condnast from "../../images/docker-swarm/customers/cond-nast.svg"
import customers__mailchimp from "../../images/docker-swarm/customers/mailchimp.svg"
import customers__sainsbury from "../../images/docker-swarm/customers/sainsbury.svg"
import customers__ebay from "../../images/docker-swarm/customers/ebay.svg"
import customers__talend from "../../images/docker-swarm/customers/talend.svg"
import cta__freetrial from "../../images/docker-swarm/cta/free-trial-icon.svg"
import cta__demo from "../../images/docker-swarm/cta/request-demo-icon.svg"

const DockerSwarmIngress = ({ data, location }) => {
  const [modal, setModal] = useState(false)
  const relatedResources = data.related_resources.edges

  useEffect(() => {
    // Load Hubspot forms in modals
    let script = document.createElement("script")
    script.src = "//js.hsforms.net/forms/v2.js"
    script.onload = function () {
      hbspt.forms.create({
        portalId: "5814022",
        formId: "6eb05b6d-d77b-4b73-b05a-4d700f82f2db",
        target: "#docker-swarm-modal-content",
      })
    }
    document.getElementsByTagName("head")[0].appendChild(script)
  }, [])

  return (
    <Layout>
      <div className="landing-page landing-page--docker-swarm">
        <MetaData
          data={{
            ghostPage: data.current_page,
          }}
          location={location}
        />

        {/* Hero */}
        <div className="page-hero docker-swarm-hero">
          <div className="wrapper-1200">
            <h1>Dynamic Docker Swarm Ingress</h1>
            <h2 className="page-hero__subtitle">
              Highly available and scalable load balancing, routing, and
              automatic service discovery for Docker Swarm and Docker Enterprise
              deployments.
            </h2>
            <div className="links">
              <button
                onClick={() =>
                  (window.location.href =
                    "https://info.traefik.io/get-traefik-enterprise-free-for-30-days")
                }
                className="button--primary button--extra-padding button--with-shadow"
              >
                Free Trial
              </button>
              <button
                onClick={() => setModal(true)}
                className="button--primary button--primary--white button--extra-padding button--with-shadow"
              >
                Request Demo
              </button>
            </div>
          </div>

          <div className="docker-swarm-hero__image page-hero__image">
            <img
              srcSet="/images/docker-swarm/hero/hero-graphic.png 1x,
                    /images/docker-swarm/hero/hero-graphic@2x.png 2x,
                    /images/docker-swarm/hero/hero-graphic@3x.png 3x"
              src="/images/docker-swarm/hero/hero-graphic.png"
              alt="Docker Swarm Ingress"
            />
          </div>
        </div>

        {/* Introduction */}
        <section className="docker-swarm-intro page-content-section">
          <div className="wrapper-1200">
            <div className="docker-swarm-technologies">
              <div className="title-uppercase">
                Integrated with the most popular cloud technologies
              </div>
              <ul>
                <li>
                  <img src={tech__docker} alt="docker" />
                </li>
                <li>
                  <img
                    srcSet="/images/docker-swarm/technologies/docker-swarm/docker-swarm.jpg 1x,
                                /images/docker-swarm/technologies/docker-swarm/docker-swarm@2x.jpg 2x,
                                /images/docker-swarm/technologies/docker-swarm/docker-swarm@3x.jpg 3x"
                    src="/images/docker-swarm/technologies/docker-swarm/docker-swarm.jpg"
                    alt="docker swarm"
                  />
                </li>
                <li>
                  <img src={tech__kubernetes} alt="kubernetes" />
                </li>
                <li>
                  <img src={tech__aws} alt="aws" />
                </li>
                <li>
                  <img src={tech__gc} alt="google cloud" />
                </li>
                <li>
                  <img src={tech__microsoft} alt="microsoft azure" />
                </li>
              </ul>
            </div>

            <div className="page-content-section__header">
              <hr className="hr-tiny" />
              <h2>
                Why use Traefik Enterprise in your Docker Swarm environment?
              </h2>
              <p className="sub-title">
                Traefik Enterprise provides a powerful load-balancing and
                ingress proxy solution for Docker Swarm and Docker Enterprise.
                Traefik for Swarm clusters is easy to use, seamlessly updates
                routes without dropping traffic, and delivers broad protocol
                support with built-in observability and control capabilities in
                a single product. Traefik Enterprise also offers a more flexible
                and reliable solution for Docker Interlock users.
              </p>
            </div>

            <div className="dashboard">
              <StaticImage
                src="../../images/docker-swarm/dashboard/traefik-ee-image.png"
                alt="Dashboard"
                width={800}
                placeholder="blurred"
              />
            </div>
          </div>
        </section>

        {/* Infrastructure */}
        <section className="docker-swarm-infrastructure page-content-section">
          <div className="wrapper-1200">
            <div className="page-content-section__header">
              <hr className="hr-tiny" />
              <h2>
                Powerful Traffic Management for Docker Swarm with Traefik
                Enterprise
              </h2>
            </div>
            <div className="page-content-section__body--columns">
              <div className="content">
                <p>
                  Traefik Enterprise controller containers--shown here in a
                  high-availability configuration on Swarm worker nodes––query
                  the Docker Engine API endpoint published on the Universal
                  Control Plane to discover the disposition of backend services
                  in the cluster. The controllers automatically update the
                  routing configuration on the proxies as service deployment
                  changes.
                </p>
                <p>
                  Traefik Enterprise proxy containers--also deployed in a
                  high-availability configuration––route incoming requests to
                  backend services. Both the control plane and the data plane
                  may be scaled independently.
                </p>
                <p>
                  In a Swarm cluster without Docker Enterprise, Traefik
                  Enterprise controllers are installed directly on manager nodes
                  to access the Docker Engine API.
                </p>
              </div>

              <div className="image">
                <img
                  src={diagram}
                  alt="Powerful Traffic Management for Docker Swarm with Traefik Enterprise"
                />
              </div>
            </div>
          </div>
        </section>

        {/* Benefits */}
        <section className="docker-swarm-benefits page-content-section">
          <div className="wrapper-1200">
            <div className="page-content-section__header">
              <h2>Traefik Enterprise Features and Benefits</h2>
            </div>

            <div className="benefits">
              <div className="benefit">
                <div className="content">
                  <h3>Simplify load balancing complexity</h3>
                  <ul>
                    <li>
                      <span>
                        <strong>Dynamic configuration.</strong> Automatically
                        discover and map all services.
                      </span>
                    </li>
                    <li>
                      <span>
                        <strong>Fast and simple.</strong> Ready in minutes, from
                        instant bootstrap to serving production traffic.
                      </span>
                    </li>
                    <li>
                      <span>
                        <strong>Advanced traffic management.</strong> Flexible
                        layer 4 and layer 7 routing plus enterprise capabilities
                        like authentication, distributed rate limiting, circuit
                        breakers, and retries.
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="illustration">
                  <img
                    src={benefits__connect}
                    alt="Simplify load balancing complexity"
                  />
                </div>
              </div>

              <div className="benefit">
                <div className="illustration">
                  <img
                    src={benefit__monitor}
                    alt="Get complete cluster visibility"
                  />
                </div>
                <div className="content">
                  <h3>Get complete cluster visibility</h3>
                  <ul>
                    <li>
                      <span>
                        <strong>Monitoring.</strong> Track the health of your
                        applications through automated collection of metrics.
                      </span>
                    </li>
                    <li>
                      <span>
                        <strong>Tracing.</strong> Visualize all application
                        flows in your infrastructure.
                      </span>
                    </li>
                    <li>
                      <span>
                        <strong>Built-in dashboard.</strong> View your
                        deployment configuration with real-time traffic metrics.
                      </span>
                    </li>
                    <li>
                      <span>
                        <strong>Full integration.</strong> Use your favorite
                        cloud-native observability tools (Datadog, Grafana,
                        InfluxDB, Prometheus, StatsD, Jaeger, Open Tracing,
                        Zipkin)
                      </span>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="benefit">
                <div className="content">
                  <h3>Ensure continuous uptime</h3>
                  <ul>
                    <li>
                      <span>
                        <strong>High availability.</strong> Fault-tolerant
                        control and data planes ensure your applications are
                        always available and safe.
                      </span>
                    </li>
                    <li>
                      <span>
                        <strong>Scalability.</strong> Independently scalable
                        control and data planes efficiently expand and contract
                        with traffic load.
                      </span>
                    </li>
                    <li>
                      <span>
                        <strong>Zero downtime.</strong> Use cluster mirrors,
                        blue-green, and canary deployments for zero-downtime
                        updates.
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="illustration">
                  <img src={benefit__secure} alt="Ensure continuous uptime" />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Customers */}
        <section className="docker-swarm-customers page-content-section">
          <div className="wrapper-1200">
            <div className="title-uppercase">
              Trusted by Leading Enterprises Worldwide
            </div>
            <ul>
              <li>
                <img src={customers__expedia} alt="expedia" />
              </li>
              <li>
                <img src={customers__condnast} alt="condé nast" />
              </li>
              <li>
                <img src={customers__mailchimp} alt="mailchimp" />
              </li>
              <li>
                <img src={customers__sainsbury} alt="sainsbury" />
              </li>
              <li>
                <img src={customers__ebay} alt="ebay" />
              </li>
              <li>
                <img src={customers__talend} alt="talend" />
              </li>
            </ul>
          </div>
        </section>

        {/* Related Resources */}
        {relatedResources.length > 0 && (
          <section
            className="docker-swarm-resources page-content-section"
            style={{
              paddingBottom: "60px",
            }}
          >
            <div className="wrapper-1200">
              <div className="page-content-section__header">
                <hr className="hr-tiny" />
                <h2>Related Resources</h2>
              </div>
              <div className="related-resource-feed">
                {relatedResources.map(({ node }) => (
                  <RelatedResourceCard key={node.id} post={node} />
                ))}
              </div>
            </div>
          </section>
        )}

        {/* CTA */}
        <section className="docker-swarm-cta page-content-section">
          <div className="wrapper-1200">
            <div className="page-content-section__header">
              <h2>Ready to get started?</h2>
            </div>
            <div className="links">
              <button
                onClick={() =>
                  (window.location.href =
                    "https://info.traefik.io/get-traefik-enterprise-free-for-30-days")
                }
                className="cta-link-card--type2"
              >
                <img src={cta__freetrial} />
                <span>Start your Free Trial</span>
              </button>
              <button
                onClick={() => setModal(true)}
                className="cta-link-card--type2"
              >
                <img src={cta__demo} />
                <span>Request Demo</span>
              </button>
            </div>
          </div>
        </section>

        {/* Modal */}
        <div
          id="docker-swarm-modal"
          className={"modal modal--docker-swarm" + (modal ? " active" : "")}
        >
          <div className="modal__container">
            <button
              type="button"
              onClick={() => setModal(false)}
              className="modal__close"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <circle cx="12" cy="12" r="10"></circle>
                <line x1="15" y1="9" x2="9" y2="15"></line>
                <line x1="9" y1="9" x2="15" y2="15"></line>
              </svg>
            </button>

            <h2 className="modal__title">
              Request a demo of Traefik Enterprise
            </h2>

            <p className="modal__sub-title">
              Please fill out the form below to get in touch with a Traefik Labs
              representative.
            </p>

            <div
              className="modal__content"
              id="docker-swarm-modal-content"
            ></div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default DockerSwarmIngress

export const query = graphql`
  {
    current_page: ghostPage(slug: { eq: "docker-swarm" }) {
      ...PageMetaFields
    }

    related_resources: allGhostPost(
      filter: {
        tags: {
          elemMatch: {
            slug: { eq: "hash-docker-swarm-ingress-related-resource" }
          }
        }
      }
      limit: 3
    ) {
      edges {
        node {
          ...GhostPostCardFields
        }
      }
    }
  }
`
